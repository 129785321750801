import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { useLanguage } from "src/contexts/languages";
import { ProjectProvider } from "src/contexts/project";
import { licencesCategoriesList } from "src/layouts/Layout_Sidebar/configurations/licences";
import { logisticsSettingsCategoriesList } from "src/layouts/Layout_Sidebar/configurations/logisticsSettings";
import { myAccountCategoriesList } from "src/layouts/Layout_Sidebar/configurations/myAccount";
import { configurationCategoriesList } from "src/layouts/Layout_Sidebar/configurations/platformConfiguration";
import { projectSettingsCategoriesList } from "src/layouts/Layout_Sidebar/configurations/projectSettings";
import { subprojectSettingsCategoriesList } from "src/layouts/Layout_Sidebar/configurations/subprojectSettings";
import { usersAndCompaniesCategoriesList } from "src/layouts/Layout_Sidebar/configurations/usersAndCompanies";

const Page_Areas = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Areas/Page_Areas"
        ),
);
const Page_Companies = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/UsersAndCompanies/Page_Companies/Page_Companies"
        ),
);
const Page_DaysOff = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_DaysOff/Page_DaysOff"
        ),
);
const Page_DefaultSettings = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_DefaultSettings/Page_DefaultSettings"
        ),
);
const Page_Drawing = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Drawings/Page_Drawing/Page_Drawing"
        ),
);
const Page_Drawings = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Drawings/Page_Drawings/Page_Drawings"
        ),
);
const Page_Library = lazy(
    () => import("src/pages/Pages_Teamoty/Library/Page_Library"),
);
const Page_KeyDates = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_KeyDates/Page_KeyDates"
        ),
);
const Page_MyProfile = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/MyAccount/Page_MyProfile/Page_MyProfile"
        ),
);
const Page_ProjectCompanies = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/Settings/Page_ProjectCompanies/Page_ProjectCompanies"
        ),
);
const Page_ProjectDetails = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/Settings/Page_ProjectDetail/Page_ProjectDetails"
        ),
);
const Page_Projects = lazy(
    () => import("src/pages/Pages_Teamoty/Page_Projects/Page_Projects"),
);
const Page_ProjectUsers = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/Settings/Page_ProjectUsers/Page_ProjectUsers"
        ),
);
const Page_Reports = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Reports/Page_Reports"
        ),
);
const Page_Resources = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Resources/Page_Resources"
        ),
);
const Page_ResourceTypes = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_ResourceTypes/Page_ResourceTypes"
        ),
);
const Page_Statistics = lazy(
    () => import("src/pages/Pages_Teamoty/Statistics/Page_Statistics"),
);
const Page_SubProjects = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/Settings/Page_SubProjects/Page_SubProjects"
        ),
);
const Page_Trades = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Page_Trades/Page_Trades"
        ),
);
const Page_Users = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/UsersAndCompanies/Page_Users/Page_Users"
        ),
);
const Page_Versions = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Project/SubProject/Settings/Versions/Page_Versions"
        ),
);

import { ProtectedRoutes } from "./ProtectedRoutes/ProtectedRoutes.container";

// Code splitting components
const Page_Demo = lazy(() => import("src/pages/Pages_Common/Page_Demo"));
const Page_DemoProject = lazy(
    () => import("src/pages/Pages_Common/Page_DemoProject"),
);

// Platform configuration

const Page_AreaTypes = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_AreaTypes/Page_AreaTypes"
        ),
);
const Page_CausesOfDelay = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_CausesOfDelay/Page_CausesOfDelay"
        ),
);
const Page_LicenseTypes = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_LicenseTypes/Page_LicenseTypes"
        ),
);
const Page_MetadataProjects = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_MetadataProjects/Page_MetadataProjects"
        ),
);
const Page_MetadataUsers = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_MetadataUsers/Page_MetadataUsers"
        ),
);
const Page_PlatformSettings = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_PlatformSettings/Page_PlatformSettings"
        ),
);
const Page_PriorityOfConstraints = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_PriorityOfConstraints/Page_PriorityOfConstraints"
        ),
);
const Page_ProjectTypes = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_ProjectTypes/Page_ProjectTypes"
        ),
);
const Page_PunchlistItemCauses = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_PunchlistItemCauses/Page_PunchlistItemCauses"
        ),
);
const Page_ResourceFamilies = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_ResourceFamilies/Page_ResourceFamilies"
        ),
);
const Page_ResourceModels = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_ResourceModels/Page_ResourceModels"
        ),
);
const Page_Roles = lazy(
    () => import("src/pages/Pages_Teamoty/Configuration/Page_Roles/Page_Roles"),
);
const Page_TimeModels = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_TimeModels/Page_TimeModels"
        ),
);
const Page_Units = lazy(
    () => import("src/pages/Pages_Teamoty/Configuration/Page_Units/Page_Units"),
);
const Page_UnitTypes = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Configuration/Page_UnitTypes/Page_UnitTypes"
        ),
);
const Page_LicencesUsers = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Licences/Page_LicencesUsers/Page_LicencesUsers"
        ),
);
const Page_LicencesProjects = lazy(
    () =>
        import(
            "src/pages/Pages_Teamoty/Licences/Page_LicencesProjects/Page_LicencesProjects"
        ),
);

// Layouts

const LayoutDashboard = lazy(() => import("src/layouts/LayoutDashboard"));
const Layout_FullWidth = lazy(() => import("src/layouts/Layout_FullWidth"));
const Layout_NotLogged = lazy(() => import("src/layouts/Layout_NotLogged"));
const Layout_Sidebar = lazy(() => import("src/layouts/Layout_Sidebar"));
const Layout_Workspace = lazy(() => import("src/layouts/Layout_Workspace"));
const Layout_Project_Workspace = lazy(
    () => import("src/layouts/Layout_Project/Layout_Project_Workspace"),
);

// Page commons

const Page_AddCompany = lazy(
    () => import("src/pages/Pages_Common/Page_AddCompany/Page_AddCompany"),
);
const Page_CGU = lazy(() => import("src/pages/Pages_Common/Page_CGU/Page_CGU"));
const Page_ChangePassword = lazy(
    () =>
        import(
            "src/pages/Pages_Common/Page_ChangePassword/Page_ChangePassword"
        ),
);
const Page_ChangePasswordConfirm = lazy(
    () =>
        import(
            "src/pages/Pages_Common/Page_ChangePasswordConfirm/Page_ChangePasswordConfirm"
        ),
);
const Page_ChangePasswordExpire = lazy(
    () =>
        import(
            "src/pages/Pages_Common/Page_ChangePasswordExpire/Page_ChangePasswordExpire"
        ),
);
const Page_ConfirmMail = lazy(
    () => import("src/pages/Pages_Common/Page_ConfirmMail/Page_ConfirmMail"),
);
const Page_Identification = lazy(
    () =>
        import(
            "src/pages/Pages_Common/Page_Identification/Page_Identification"
        ),
);
const Page_NewPassword = lazy(
    () => import("src/pages/Pages_Common/Page_NewPassword/Page_NewPassword"),
);
const Page_NewPasswordConfirm = lazy(
    () =>
        import(
            "src/pages/Pages_Common/Page_NewPasswordConfirm/Page_NewPasswordConfirm"
        ),
);
const Page_Register = lazy(
    () => import("src/pages/Pages_Common/Page_Register/Page_Register"),
);
const Page_ResetPassword = lazy(
    () =>
        import("src/pages/Pages_Common/Page_ResetPassword/Page_ResetPassword"),
);
const Page_ResetPasswordConfirm = lazy(
    () =>
        import(
            "src/pages/Pages_Common/Page_ResetPasswordConfirm/Page_ResetPasswordConfirm"
        ),
);

const Page_UserMetadata = lazy(
    () => import("src/pages/Pages_Common/Page_UserMetadata"),
);

// Page Logistics

const Page_AssignManager = lazy(
    () =>
        import(
            "src/pages/Pages_Logistics/Page_AssignManager/Page_AssignManager"
        ),
);
const Page_AssignResources = lazy(
    () =>
        import(
            "src/pages/Pages_Logistics/Page_AssignResources/Page_AssignResources"
        ),
);
const Page_RequestTypes = lazy(
    () =>
        import("src/pages/Pages_Logistics/Page_RequestTypes/Page_RequestTypes"),
);

// Pages Scheduling

const Page_SequencesFlow = lazy(
    () => import("src/pages/Pages_Scheduling/Page_SequencesFlow"),
);

// Path constants

export const URL_BASE: string = "/:lang";

export const URL_USERS_MANAGEMENT: string = `${URL_BASE}/users-and-companies`;
export const URL_LICENCES: string = `${URL_BASE}/licences`;
export const URL_MY_ACCOUNT: string = `${URL_BASE}/my-account`;
export const URL_CONFIGURATION_PLATFORM: string = `${URL_BASE}/platform-configuration`;
export const URL_TEAMOTY_PROJECT: string = `${URL_BASE}/projects/:projectSlug`;
export const URL_LOGISTICS: string = `${URL_TEAMOTY_PROJECT}/logistics`;
export const URL_SCHEDULING: string = `${URL_TEAMOTY_PROJECT}/scheduling`;
export const URL_RECYCLE: string = `${URL_TEAMOTY_PROJECT}/recycle`;
export const URL_MY_TEAMOTY: string = `${URL_TEAMOTY_PROJECT}/myteamoty`;

export const URL_SUBPROJECT_SCHEDULING: string = `${URL_TEAMOTY_PROJECT}/:subProjectId/scheduling`;

export const Router = () => {
    const { language } = useLanguage();

    return (
        <Suspense fallback={<></>}>
            <Routes>
                {/* Routes d'authentification (connexion, enregistrement, mdp perdu) */}
                <Route path={`${URL_BASE}/*`} element={<Layout_NotLogged />}>
                    <Route path={`login`}>
                        <Route
                            path={`:msg`}
                            element={<Page_Identification />}
                        />
                        <Route path={""} element={<Page_Identification />} />
                    </Route>
                    <Route
                        path={`projects/:project/login/`}
                        element={<Page_Identification />}
                    />

                    <Route
                        path={`user-invitations/register/:token`}
                        element={<Page_Register />}
                    />
                    <Route
                        path={`confirm-mail/:id/:hash`}
                        element={<Page_ConfirmMail />}
                    />
                    <Route
                        path={`reset-password`}
                        element={<Page_ResetPassword />}
                    />
                    <Route
                        path={`reset-password-confirm`}
                        element={<Page_ResetPasswordConfirm />}
                    />
                    <Route
                        path={`new-password`}
                        element={<Page_NewPassword />}
                    />
                    <Route
                        path={`new-password-confirm`}
                        element={<Page_NewPasswordConfirm />}
                    />
                    <Route
                        path={`change-password`}
                        element={<Page_ChangePassword />}
                    />
                    <Route
                        path={`change-password-confirm`}
                        element={<Page_ChangePasswordConfirm />}
                    />
                    <Route
                        path={`change-password-expire`}
                        element={<Page_ChangePasswordExpire />}
                    />
                </Route>

                {/* Routes demandant un utilisateur connecté */}
                <Route
                    element={
                        <ProtectedRoutes>
                            <Layout_NotLogged />
                        </ProtectedRoutes>
                    }
                >
                    <Route path={`${URL_BASE}/cgu`} element={<Page_CGU />} />
                    <Route
                        path={`${URL_BASE}/add-company`}
                        element={
                            <ProjectProvider>
                                <Page_AddCompany />
                            </ProjectProvider>
                        }
                    />
                    <Route
                        path={`${URL_BASE}/fill-metadata`}
                        element={<Page_UserMetadata />}
                    />
                </Route>

                <Route
                    element={
                        <ProtectedRoutes>
                            <ProjectProvider>
                                <LayoutDashboard />
                            </ProjectProvider>
                        </ProtectedRoutes>
                    }
                >
                    <Route path={`${URL_BASE}/demo`} element={<Page_Demo />} />
                    {/* TODO: will be replace by dashboard or views */}
                    <Route
                        path={`${URL_BASE}/projects`}
                        element={<Page_Projects />}
                    />
                    <Route
                        path={`${URL_BASE}/library`}
                        element={<Page_Library />}
                    />{" "}
                    <Route
                        path={`${URL_BASE}/statistics`}
                        element={<Page_Statistics />}
                    />
                    {/* Urls de /users-and-companies */}
                    <Route
                        path={`${URL_USERS_MANAGEMENT}/*`}
                        element={
                            <Layout_Sidebar
                                configuration={usersAndCompaniesCategoriesList}
                            />
                        }
                    >
                        <Route path={`users`} element={<Page_Users />} />
                        <Route
                            path={`companies`}
                            element={<Page_Companies />}
                        />
                        {/* --> REDIRECTION : */}
                        <Route path={`*`} element={<Navigate to={`users`} />} />
                    </Route>
                    {/* Urls de /licences */}
                    <Route
                        path={`${URL_LICENCES}/*`}
                        element={
                            <Layout_Sidebar
                                configuration={licencesCategoriesList}
                            />
                        }
                    >
                        <Route
                            path={`users`}
                            element={<Page_LicencesUsers />}
                        />
                        <Route
                            path={`projects`}
                            element={<Page_LicencesProjects />}
                        />
                        {/* --> REDIRECTION : */}
                        <Route path={`*`} element={<Navigate to={`users`} />} />
                    </Route>
                    {/* Urls de /my-account : */}
                    <Route
                        path={`${URL_MY_ACCOUNT}/*`}
                        element={
                            <Layout_Sidebar
                                configuration={myAccountCategoriesList}
                            />
                        }
                    >
                        <Route
                            path={"my-profile"}
                            element={<Page_MyProfile />}
                        />

                        {/* --> REDIRECTION : */}
                        <Route
                            path={`*`}
                            element={<Navigate to={`my-profile`} />}
                        />
                    </Route>
                    {/* Urls de /configuration platform : */}
                    <Route
                        path={`${URL_CONFIGURATION_PLATFORM}/*`}
                        element={
                            <Layout_Sidebar
                                configuration={configurationCategoriesList}
                            />
                        }
                    >
                        <Route path={`projects/*`}>
                            <Route
                                path={`project-types`}
                                element={<Page_ProjectTypes />}
                            />
                            <Route
                                path={`area-types`}
                                element={<Page_AreaTypes />}
                            />
                            <Route
                                path={`*`}
                                element={<Navigate to={`project-types`} />}
                            />
                        </Route>
                        <Route path={`roles`} element={<Page_Roles />} />
                        <Route
                            path={`license-types`}
                            element={<Page_LicenseTypes />}
                        />
                        <Route path={`units/*`}>
                            <Route path={`units`} element={<Page_Units />} />
                            <Route
                                path={`unit-types`}
                                element={<Page_UnitTypes />}
                            />
                            <Route
                                path={`*`}
                                element={<Navigate to={`units`} />}
                            />
                        </Route>
                        <Route
                            path={`time-models`}
                            element={<Page_TimeModels />}
                        />
                        <Route
                            path={`platform-settings`}
                            element={<Page_PlatformSettings />}
                        />
                        <Route
                            path={`priority-of-constraints`}
                            element={<Page_PriorityOfConstraints />}
                        />
                        <Route path={`causes/*`}>
                            <Route
                                path={`causes-of-delay`}
                                element={<Page_CausesOfDelay />}
                            />
                            <Route
                                path={`punchlist-item-causes`}
                                element={<Page_PunchlistItemCauses />}
                            />
                            <Route
                                path={`*`}
                                element={<Navigate to={`causes-of-delay`} />}
                            />
                        </Route>
                        <Route path={`resources/*`}>
                            <Route
                                path={`resource-models`}
                                element={<Page_ResourceModels />}
                            />
                            <Route
                                path={`resource-families`}
                                element={<Page_ResourceFamilies />}
                            />
                            <Route
                                path={`*`}
                                element={<Navigate to={`resource-models`} />}
                            />
                        </Route>
                        <Route path={`metadata/*`}>
                            <Route
                                path={`projects`}
                                element={<Page_MetadataProjects />}
                            />
                            <Route
                                path={`users`}
                                element={<Page_MetadataUsers />}
                            />
                            <Route
                                path={`*`}
                                element={<Navigate to={`projects`} />}
                            />
                        </Route>
                        {/* --> REDIRECTION */}
                        <Route path={`*`} element={<Navigate to={`roles`} />} />
                    </Route>
                    {/*
                Projects routes /:lang/projects/:projectSlug > Workspace routes
                */}
                    <Route
                        path={`${URL_TEAMOTY_PROJECT}`}
                        element={<Layout_Project_Workspace />}
                    >
                        {/* MY TEAMOTY */}
                        <Route path={`myteamoty/*`}>
                            {/* Workspace */}
                            <Route path={`workspace`}>
                                <Route
                                    index
                                    path={":view"}
                                    element={<Layout_Workspace />}
                                />
                                <Route
                                    index
                                    path={"*"}
                                    element={<Layout_Workspace />}
                                />
                            </Route>
                            {/* --> REDIRECTION */}
                            <Route
                                path={`*`}
                                element={<Navigate to={`workspace`} />}
                            />
                        </Route>

                        {/* LOGISTICS */}
                        <Route path={`logistics/*`}>
                            {/* Workspace */}
                            <Route path={`workspace`}>
                                <Route
                                    index
                                    path={":view"}
                                    element={<Layout_Workspace />}
                                />
                                <Route
                                    index
                                    path={"*"}
                                    element={<Layout_Workspace />}
                                />
                                {/* --> REDIRECTION */}
                                <Route
                                    path={`*`}
                                    element={<Navigate to={`workspace`} />}
                                />
                            </Route>
                        </Route>

                        <Route path={`scheduling/*`}>
                            {/* Workspace */}
                            <Route path={`workspace`}>
                                <Route
                                    index
                                    path={":view"}
                                    element={<Layout_Workspace />}
                                />
                                <Route
                                    index
                                    path={"*"}
                                    element={<Layout_Workspace />}
                                />
                            </Route>
                            {/* --> REDIRECTION */}
                            <Route
                                path={`*`}
                                element={<Navigate to={`workspace`} />}
                            />
                        </Route>

                        {/* SUB-PROJECT */}
                        <Route path={`:subProjectId`}>
                            <Route path={`myteamoty/*`}>
                                {/* Workspace */}
                                <Route path={`workspace`}>
                                    <Route
                                        index
                                        path={":view"}
                                        element={<Layout_Workspace />}
                                    />
                                    <Route
                                        index
                                        path={"*"}
                                        element={<Layout_Workspace />}
                                    />
                                </Route>
                                {/* --> REDIRECTION */}
                                <Route
                                    path={`*`}
                                    element={<Navigate to={`workspace`} />}
                                />
                            </Route>
                            {/* SCHEDULING */}
                            <Route
                                path={`scheduling/*`}
                                element={<Layout_FullWidth />}
                            >
                                <Route
                                    path={`sequences-flow`}
                                    element={<Page_SequencesFlow />}
                                />
                            </Route>
                        </Route>
                    </Route>
                    {/* Projects routes /:lang/projects/:projectSlug > other routes */}
                    <Route path={`${URL_TEAMOTY_PROJECT}`}>
                        <Route path={`logistics/*`}>
                            <Route
                                path={`settings/*`}
                                element={
                                    <Layout_Sidebar
                                        configuration={
                                            logisticsSettingsCategoriesList
                                        }
                                    />
                                }
                            >
                                <Route
                                    path={`request-types`}
                                    element={<Page_RequestTypes />}
                                />
                                <Route
                                    path={`assign-manager`}
                                    element={<Page_AssignManager />}
                                />
                                <Route
                                    path={`assign-resources`}
                                    element={<Page_AssignResources />}
                                />
                                {/* --> REDIRECTION */}
                                <Route
                                    path={`*`}
                                    element={<Navigate to={`request-types`} />}
                                />
                            </Route>
                            {/* --> REDIRECTION */}
                            <Route
                                path={`*`}
                                element={<Navigate to={`workspace`} />}
                            />
                        </Route>

                        {/* SCHEDULING */}
                        <Route
                            path={`scheduling/*`}
                            element={
                                <div>
                                    <h1>scheduling Route</h1>
                                    <p>
                                        This is a test paragraph for the
                                        scheduling route.
                                    </p>
                                </div>
                            }
                        ></Route>

                        {/* RECYCLE */}
                        <Route
                            path={`recycle/*`}
                            element={
                                <div>
                                    <h1>Recycle Route</h1>
                                    <p>
                                        This is a test paragraph for the Recycle
                                        route.
                                    </p>
                                </div>
                            }
                        ></Route>

                        {/* PROJECT SETTINGS */}
                        <Route
                            path={`settings/*`}
                            element={
                                <Layout_Sidebar
                                    configuration={
                                        projectSettingsCategoriesList
                                    }
                                    configurationSubProjects={
                                        subprojectSettingsCategoriesList
                                    }
                                />
                            }
                        >
                            <Route
                                path={`subprojects`}
                                element={<Page_SubProjects />}
                            />
                            <Route
                                path={`companies`}
                                element={<Page_ProjectCompanies />}
                            />
                            <Route
                                path={`project-details`}
                                element={<Page_ProjectDetails />}
                            />
                            <Route path={`trades`} element={<Page_Trades />} />

                            <Route
                                path={`users`}
                                element={<Page_ProjectUsers />}
                            />

                            <Route
                                path={`days-off`}
                                element={<Page_DaysOff />}
                            />
                            <Route path={`resources/*`}>
                                <Route
                                    path={`resources`}
                                    element={<Page_Resources />}
                                />
                                <Route
                                    path={`resource-types`}
                                    element={<Page_ResourceTypes />}
                                />
                                <Route
                                    path={`*`}
                                    element={<Navigate to={`resources`} />}
                                />
                            </Route>

                            {/* SUBPROJECT SETTINGS */}
                            <Route path={`subproject/:subProjectId/*`}>
                                <Route
                                    path={`companies`}
                                    element={<Page_ProjectCompanies />}
                                />
                                <Route
                                    path={`trades`}
                                    element={<Page_Trades />}
                                />
                                <Route
                                    path={`areas`}
                                    element={<Page_Areas />}
                                />
                                <Route
                                    path={`versions`}
                                    element={<Page_Versions />}
                                />
                                <Route
                                    path={`drawings`}
                                    element={<Page_Drawings />}
                                />
                                <Route
                                    path={`user`}
                                    element={<Page_ProjectUsers />}
                                />
                                <Route
                                    path={`days-off`}
                                    element={<Page_DaysOff />}
                                />
                                <Route
                                    path={`reports`}
                                    element={<Page_Reports />}
                                />
                                <Route path={`resources/*`}>
                                    <Route
                                        path={`resources`}
                                        element={<Page_Resources />}
                                    />
                                    <Route
                                        path={`resource-types`}
                                        element={<Page_ResourceTypes />}
                                    />
                                    <Route
                                        path={`*`}
                                        element={<Navigate to={`resources`} />}
                                    />
                                </Route>
                                <Route
                                    path={`key-dates`}
                                    element={<Page_KeyDates />}
                                />
                                <Route
                                    path={`default-settings`}
                                    element={<Page_DefaultSettings />}
                                />

                                {/* --> REDIRECTION */}
                                <Route
                                    path={`*`}
                                    element={<Navigate to={`areas`} />}
                                />
                            </Route>

                            {/*/!* --> REDIRECTION *!/*/}
                            <Route
                                path={`*`}
                                element={<Navigate to={`subprojects`} />}
                            />
                        </Route>

                        {/* SUB-PROJECT WITH DIFFERENT LAYOUT*/}
                        <Route
                            path={`:subProjectId`}
                            element={<Layout_FullWidth />}
                        >
                            <Route path={`drawings`}>
                                <Route
                                    path={`new`}
                                    element={<Page_Drawing />}
                                />
                                <Route
                                    path={`:drawingId`}
                                    element={<Page_Drawing />}
                                />
                            </Route>
                            <Route
                                path={`demo`}
                                element={<Page_DemoProject />}
                            />
                        </Route>

                        {/* --> REDIRECTION */}
                        <Route
                            path={`*`}
                            element={<Navigate to={`settings`} />}
                        />
                    </Route>
                </Route>
                {/* Redirection en cas de mauvaise route */}
                <Route
                    path="*"
                    element={<Navigate to={`/${language}/login`} />}
                />
            </Routes>
        </Suspense>
    );
};
